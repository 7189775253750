import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { computed, onMounted } from 'vue';
import moment from 'moment';
var oneDay = 86400000;
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        modelValue: {},
        start: {},
        end: {},
        defaultDate: {},
        type: { default: 'date' },
        valueFormat: { default: 'YYYY-MM-DD' },
        optionalScope: { default: 'before' },
        days: {}
    },
    emits: ['update:modelValue', 'update:start', 'update:end'],
    setup: function (__props, _a) {
        var __emit = _a.emit;
        var props = __props;
        var emits = __emit;
        // 双向绑定
        var datePickerModel = computed({
            get: function () {
                switch (props.type) {
                    case 'date':
                        return props.modelValue || '';
                    case 'daterange':
                        return [props.start || '', props.end || ''];
                }
                return undefined;
            },
            set: function (val) {
                switch (props.type) {
                    case 'date':
                        emits('update:modelValue', val);
                        break;
                    case 'daterange':
                        emits('update:start', val ? val[0] : '');
                        emits('update:end', val ? val[1] : '');
                        startTime = 0;
                }
            }
        });
        // 默认值
        onMounted(function () {
            if (props.type === 'daterange') {
                if (Array.isArray(props.defaultDate)) {
                    emits('update:start', props.defaultDate[0]);
                    emits('update:end', props.defaultDate[1]);
                }
                else if (props.defaultDate) {
                    emits('update:start', moment().subtract(props.defaultDate, 'd').format(props.valueFormat));
                    emits('update:end', moment().format(props.valueFormat));
                }
            }
        });
        // 限制范围
        var startTime = 0;
        var calendarChange = function (e) {
            if (props.days) {
                startTime = e[0].getTime();
            }
        };
        var disabledDate = function (date) {
            var today = new Date(moment().format('YYYY-MM-DD 00:00:00')).getTime();
            switch (props.optionalScope) {
                case 'before':
                    if (date.getTime() > today)
                        return true;
                    break;
                case 'after':
                    if (date.getTime() < today)
                        return true;
            }
            if (startTime && props.days) {
                var minDate = startTime - oneDay * (props.days - 1);
                var maxDate = startTime + oneDay * (props.days - 1);
                return date.getTime() < minDate || date.getTime() > maxDate;
            }
            return false;
        };
        return function (_ctx, _cache) {
            var _component_el_date_picker = _resolveComponent("el-date-picker");
            return (_openBlock(), _createBlock(_component_el_date_picker, {
                modelValue: datePickerModel.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((datePickerModel).value = $event); }),
                type: props.type,
                onCalendarChange: calendarChange,
                "disabled-date": disabledDate,
                "value-format": props.valueFormat,
                clearable: false,
                placeholder: "选择日期",
                "start-placeholder": "开始日期",
                "end-placeholder": "结束日期"
            }, null, 8, ["modelValue", "type", "value-format"]));
        };
    }
});

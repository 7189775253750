import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "ac_sidebar-logo" };
var _hoisted_2 = { class: "flex-1 overflow-hidden" };
import MenuBar from './menu/index.vue';
import { computed, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup: function (__props) {
        var store = useStore();
        var route = useRoute();
        onMounted(function () {
            window.addEventListener('resize', winResize);
        });
        onUnmounted(function () {
            window.removeEventListener('resize', winResize);
        });
        var collapse = computed(function () { return store.getters['layout/collapse']; });
        // section 缩放监听
        var historyWidth = window.outerWidth;
        var winResize = function (e) {
            // 逐渐缩小
            var width = e.target.outerWidth;
            if (width < 1200 && width < historyWidth) {
                store.commit('layout/setCollapse', true);
            }
            historyWidth = width;
        };
        return function (_ctx, _cache) {
            var _component_el_image = _resolveComponent("el-image");
            var _component_el_scrollbar = _resolveComponent("el-scrollbar");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["ac_sidebar", { 'is-collapse': collapse.value }])
            }, [
                _createElementVNode("div", _hoisted_1, [
                    (!collapse.value)
                        ? (_openBlock(), _createBlock(_component_el_image, {
                            key: 0,
                            class: "wh-100",
                            fit: "contain",
                            src: require('@images/logo/logo-long.png'),
                            style: { "width": "165px" }
                        }, null, 8, ["src"]))
                        : (_openBlock(), _createBlock(_component_el_image, {
                            key: 1,
                            class: "wh-100",
                            fit: "contain",
                            src: require('@images/logo/mini-logo.png'),
                            style: { "width": "50px" }
                        }, null, 8, ["src"]))
                ]),
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_el_scrollbar, null, {
                        default: _withCtx(function () { return [
                            _createVNode(MenuBar)
                        ]; }),
                        _: 1
                    })
                ])
            ], 2));
        };
    }
});

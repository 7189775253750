import { __awaiter, __generator } from "tslib";
import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import locale from 'element-plus/es/locale/lang/zh-cn';
import { onMounted } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup: function (__props) {
        var _this = this;
        onMounted(function () {
            t2();
        });
        var t = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                throw window.PromiseReject({ a: 1 });
            });
        }); };
        var t2 = function () { return __awaiter(_this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, t().catch(function (e) {
                                // console.log(e.message, 1)
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_1 = _a.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return function (_ctx, _cache) {
            var _component_router_view = _resolveComponent("router-view");
            var _component_el_scrollbar = _resolveComponent("el-scrollbar");
            var _component_el_config_provider = _resolveComponent("el-config-provider");
            return (_openBlock(), _createBlock(_component_el_config_provider, { locale: _unref(locale) }, {
                default: _withCtx(function () { return [
                    _createVNode(_component_el_scrollbar, null, {
                        default: _withCtx(function () { return [
                            _createVNode(_component_router_view)
                        ]; }),
                        _: 1
                    })
                ]; }),
                _: 1
            }, 8, ["locale"]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue";
var _hoisted_1 = { class: "empty-box__image" };
var _hoisted_2 = ["hidden"];
import { onMounted, ref, watch } from 'vue';
import { ObjectUtil } from '@/utils/ObjectUtil';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    props: {
        state: { type: [String, Number, Boolean, Error, Object] },
        imageSize: { default: 180 },
        emptyClass: {},
        emptyStyle: {},
        height: {}
    },
    setup: function (__props) {
        var props = __props;
        // 是否显示空状态
        var emptyIsShow = ref(true);
        // 提示信息
        var descriptionTip = ref('暂无数据');
        // 图片地址
        var imageSrc = ref('');
        // 当前状态码值
        var statusCode = ref(null);
        // 状态字典
        var statusDictionary = {
            empty: { src: require('./images/empty.png'), description: '暂无数据' },
            'empty-text': { src: '', description: '暂无数据' },
            'empty-chart': { src: require('./images/empty-chart.png'), description: '暂无数据' },
            'empty-var': { src: require('./images/empty-var.png'), description: '当前无内容，请选择变量' },
            timeout: { src: require('./images/other.png'), description: '连接超时！' },
            other: { src: require('./images/other.png'), description: '哎呀，出错啦~' },
            code: { src: require('./images/code.png'), description: ' ' },
            none: { src: '', description: ' ' },
        };
        // 状态码字典
        var statusCodeDictionary = {
            400: '请求参数有误',
            404: '迷路了，找不到您访问的资源~',
            500: '抱歉，服务器出错了~',
            403: '抱歉，您无权访问此资源~',
        };
        onMounted(function () {
            updateState(props.state);
        });
        /**
         * 更新当前显示状态
         * @param state
         */
        var updateState = function (state) {
            // true ==> 'success', false ==> 'empty'
            if (state === true || state === 'success') {
                emptyIsShow.value = false;
                return;
            }
            // 重置变量
            statusCode.value = null;
            var stateInfo = {
                type: 'empty', description: '', image: '',
            };
            // 根据参数更新状态信息
            if (ObjectUtil.isString(state)) {
                stateInfo.type = state;
            }
            else if (ObjectUtil.isNumber(state)) {
                stateInfo.type = 'code';
                statusCode.value = state;
            }
            else if (ObjectUtil.isPrototype(state, 'Error')) {
                // 验证是否是请求超时
                if (state.toString().indexOf('timeout') > -1) {
                    stateInfo.type = 'timeout';
                }
                else {
                    // 否则提取错误码
                    var code = state.toString().replace(/^.*[code] (.*)$/, '$1');
                    if (!isNaN(Number(code))) {
                        stateInfo.type = 'code';
                        statusCode.value = Number(code);
                    }
                }
            }
            else if (ObjectUtil.isObject(state)) {
                Object.assign(stateInfo, state);
            }
            // 如果无描述信息且有错误码,则按错误码提示
            if (statusCode.value && !stateInfo.description) {
                stateInfo.description = statusCodeDictionary[statusCode.value] || '';
            }
            var temp = statusDictionary[stateInfo.type];
            !temp && (temp = statusDictionary.other);
            imageSrc.value = stateInfo.image || temp.src;
            descriptionTip.value = stateInfo.description || temp.description;
            emptyIsShow.value = true;
        };
        watch(function () { return props.state; }, updateState);
        return function (_ctx, _cache) {
            var _component_el_image = _resolveComponent("el-image");
            var _component_el_empty = _resolveComponent("el-empty");
            return (_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(["empty-box", { 'is-center': emptyIsShow.value }]),
                style: _normalizeStyle({ height: _ctx.height })
            }, [
                (emptyIsShow.value)
                    ? (_openBlock(), _createBlock(_component_el_empty, {
                        key: 0,
                        class: _normalizeClass(_ctx.emptyClass),
                        style: _normalizeStyle(_ctx.emptyStyle),
                        "image-size": _ctx.imageSize,
                        description: descriptionTip.value
                    }, {
                        image: _withCtx(function () { return [
                            _createElementVNode("div", _hoisted_1, [
                                (statusCode.value)
                                    ? (_openBlock(), _createElementBlock("div", {
                                        key: 0,
                                        class: "empty-box__code",
                                        style: _normalizeStyle({ fontSize: (_ctx.imageSize / 1.5) + 'px' })
                                    }, _toDisplayString(statusCode.value), 5))
                                    : _createCommentVNode("", true),
                                (imageSrc.value)
                                    ? (_openBlock(), _createBlock(_component_el_image, {
                                        key: 1,
                                        src: imageSrc.value
                                    }, null, 8, ["src"]))
                                    : _createCommentVNode("", true)
                            ])
                        ]; }),
                        default: _withCtx(function () { return [
                            _renderSlot(_ctx.$slots, "footer")
                        ]; }),
                        _: 3
                    }, 8, ["class", "style", "image-size", "description"]))
                    : _createCommentVNode("", true),
                _createElementVNode("div", {
                    class: "empty-box_body",
                    hidden: emptyIsShow.value
                }, [
                    _renderSlot(_ctx.$slots, "default")
                ], 8, _hoisted_2)
            ], 6));
        };
    }
});

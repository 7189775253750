import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { onMounted, onUnmounted, ref } from 'vue';
import { ElMessage } from 'element-plus';
import screenfull from 'screenfull';
export default /*@__PURE__*/ _defineComponent({
    __name: 'ScreenFull',
    setup: function (__props) {
        onMounted(function () {
            if (screenfull.isEnabled) {
                screenfull.on('change', changeEvent);
            }
        });
        var isFullscreen = ref(false);
        var screenToggle = function () {
            if (!screenfull.isEnabled) {
                ElMessage.warning('您的浏览器不支持全屏功能');
                return false;
            }
            screenfull.toggle();
        };
        var changeEvent = function () {
            isFullscreen.value = screenfull.isFullscreen;
        };
        onUnmounted(function () {
            // 删除监听器
            if (screenfull.isEnabled) {
                screenfull.off('change', changeEvent);
            }
        });
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("i", {
                class: _normalizeClass(["c-icon", isFullscreen.value ? 'ci-out-full-screen' : 'ci-full-screen']),
                onClick: screenToggle
            }, null, 2));
        };
    }
});

import { __assign } from "tslib";
import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'MenuItem',
    props: {
        menu: {}
    },
    setup: function (__props) {
        var props = __props;
        var onlyOneChild = ref();
        /**
         * 一个展开的子菜单
         */
        var hasOneShowingChild = function () {
            var _a, _b;
            if (!props.menu.children || props.menu.children.length === 0) {
                onlyOneChild.value = __assign({}, props.menu);
                return true;
            }
            else if (props.menu.children.length === 1 && !((_b = (_a = props.menu) === null || _a === void 0 ? void 0 : _a.children[0].children) === null || _b === void 0 ? void 0 : _b.length)) {
                onlyOneChild.value = props.menu.children[0];
                return true;
            }
            return false;
        };
        return function (_ctx, _cache) {
            var _component_el_menu_item = _resolveComponent("el-menu-item");
            var _component_router_link = _resolveComponent("router-link");
            var _component_menu_item = _resolveComponent("menu-item", true);
            var _component_el_sub_menu = _resolveComponent("el-sub-menu");
            return (hasOneShowingChild())
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: onlyOneChild.value.path
                }, {
                    default: _withCtx(function () { return [
                        _createVNode(_component_el_menu_item, {
                            index: onlyOneChild.value.name
                        }, {
                            title: _withCtx(function () { return [
                                _createElementVNode("span", null, _toDisplayString(onlyOneChild.value.meta.title), 1)
                            ]; }),
                            default: _withCtx(function () { return [
                                _createElementVNode("i", {
                                    class: _normalizeClass(["iconfont", [onlyOneChild.value.meta.icon]])
                                }, null, 2)
                            ]; }),
                            _: 1
                        }, 8, ["index"])
                    ]; }),
                    _: 1
                }, 8, ["to"]))
                : (_openBlock(), _createBlock(_component_el_sub_menu, {
                    key: 1,
                    index: _ctx.menu.name
                }, {
                    title: _withCtx(function () { return [
                        _createElementVNode("i", {
                            class: _normalizeClass(["iconfont", [_ctx.menu.meta.icon]])
                        }, null, 2),
                        _createElementVNode("span", null, _toDisplayString(_ctx.menu.meta.title), 1)
                    ]; }),
                    default: _withCtx(function () { return [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.children, function (child) {
                            return (_openBlock(), _createBlock(_component_menu_item, {
                                key: child.name,
                                menu: child
                            }, null, 8, ["menu"]));
                        }), 128))
                    ]; }),
                    _: 1
                }, 8, ["index"]));
        };
    }
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "message_box" }

import { onMounted, ref, computed, onUnmounted } from 'vue'
import { getAlarmAPI } from '@/api/alarm'
import { recentDate } from '@utils/moment'
import settings from '@/settings'
import { useStore } from 'vuex'
import { ElNotification } from 'element-plus'


export default {
  __name: 'Remind',
  setup(__props) {

const store = useStore()
const isDot = ref(false)
onMounted(() => {
  getAlarmData()
})
const getAlarmData = () => {
  const [beginDate, endDate] = recentDate(7)
  getAlarmAPI({ beginDate, endDate, alarmState: '' }).then(res => {
    res.result().then((rdata) => {
      isDot.value = rdata.payload.total > 0
      websocket()
    })
  })
}

// websocket
const onmessage = (e) => {
  const res = JSON.parse(e.data)
  if(res.code === 200) {
    const siteName = res.payload.siteName
    const data = res.payload.data || []
    data.forEach((alarm) => {
      ElNotification({
        title: siteName,
        message: `【${alarm.variableAddress}】${alarm.alarmMessage}`,
        type: alarm.level === 1 ? 'error' : 'warning',
        duration: 20000
      })
    })
  }
}

const userInfo = computed(() => store.getters['app/userInfo'])
let ws = null
// let ws2 = null
const websocket = () => {
  ws = new WebSocket(settings.WEBSOCKET_API + '/api/weile/' + userInfo.value.id)
  // ws2 = new WebSocket(settings.WEBSOCKET_API2 + '/api/weile/' + userInfo.value.id)
  ws.onmessage = onmessage
  ws.onopen = function() {
    console.log('已连接')
  }
  ws.onclose = function() {
    console.log('已断开')
  }
  ws.onerror = function(e) {
    console.log(e)
  }
  ping()
  // ws2.onmessage = onmessage
}
function ping() {
  if(ws) {
    setTimeout(function() {
      ws.send('ping')
      ping()
    }, 10000)
  }
}
onUnmounted(() => {
  ws && ws.close()
  // ws2 && ws2.close()
})

return (_ctx, _cache) => {
  const _component_router_link = _resolveComponent("router-link")
  const _component_el_badge = _resolveComponent("el-badge")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_badge, {
      "is-dot": isDot.value,
      class: "item"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: "/alarm/manage/history",
          onClick: _cache[0] || (_cache[0] = $event => (isDot.value = false))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createElementVNode("i", { class: "c-icon ci-remind" }, null, -1)
          ])),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["is-dot"])
  ]))
}
}

}
import { defineComponent as _defineComponent } from 'vue';
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref } from 'vue';
import { getMenuList } from '@/layout/components/sidebar/menu/menuFactory';
import { useRouter } from 'vue-router';
/**
 * 获取菜单列表 - 面包屑:地址
 * @param condition
 */
export default /*@__PURE__*/ _defineComponent({
    __name: 'HeaderSearch',
    setup: function (__props) {
        var router = useRouter();
        // 搜索条件
        var searchParam = ref('');
        /**
         * 搜索
         * @param queryString
         * @param cb
         */
        var search = function (queryString, cb) {
            cb(getBreadcrumbList(queryString));
        };
        var getBreadcrumbList = function (condition) {
            var menuList = getMenuList();
            var menuLinkList = [];
            recursion(menuList);
            function recursion(menus, parent) {
                var _a, _b, _c, _d;
                for (var i = 0, len = menus.length; i < len; i++) {
                    var menu = menus[i];
                    var title = (_a = menu === null || menu === void 0 ? void 0 : menu.meta) === null || _a === void 0 ? void 0 : _a.title;
                    if (parent && title) {
                        menu.meta.title = ((_b = parent === null || parent === void 0 ? void 0 : parent.meta) === null || _b === void 0 ? void 0 : _b.title) + '/' + title;
                    }
                    if ((_c = menu.children) === null || _c === void 0 ? void 0 : _c.length) {
                        recursion(menu.children, title ? menu : undefined);
                    }
                    else if ((_d = menu === null || menu === void 0 ? void 0 : menu.meta) === null || _d === void 0 ? void 0 : _d.title) {
                        if (condition) {
                            if (menu.meta.title.toLowerCase().indexOf(condition.toLowerCase()) > -1) {
                                menuLinkList.push({ value: menu.meta.title, path: menu.path });
                            }
                        }
                        else {
                            menuLinkList.push({ value: menu.meta.title, path: menu.path });
                        }
                    }
                }
            }
            return menuLinkList;
        };
        /**
         * 选择事件
         * @param item
         */
        var handleSelect = function (item) {
            router.push(item.path);
        };
        return function (_ctx, _cache) {
            var _component_el_autocomplete = _resolveComponent("el-autocomplete");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_el_autocomplete, {
                    class: "ac_header-search",
                    modelValue: searchParam.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) { return ((searchParam).value = $event); }),
                    "fetch-suggestions": search,
                    placeholder: "请输入搜索内容",
                    onSelect: handleSelect
                }, null, 8, ["modelValue"])
            ]));
        };
    }
});

import { defineComponent as _defineComponent } from 'vue';
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "d-flex-center cursor-pointer" };
var _hoisted_2 = { class: "ml-2" };
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { removeToken } from '@/utils/auth';
export default /*@__PURE__*/ _defineComponent({
    __name: 'UserInfo',
    setup: function (__props) {
        var router = useRouter();
        var store = useStore();
        var userInfo = computed(function () { return store.getters['app/userInfo']; });
        /**
         * 退出登录
         */
        var logout = function () {
            removeToken();
            router.replace('/login');
        };
        return function (_ctx, _cache) {
            var _component_router_link = _resolveComponent("router-link");
            var _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
            var _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
            var _component_el_dropdown = _resolveComponent("el-dropdown");
            return (_openBlock(), _createElementBlock("div", null, [
                _createVNode(_component_el_dropdown, { trigger: "click" }, {
                    dropdown: _withCtx(function () { return [
                        _createVNode(_component_el_dropdown_menu, null, {
                            default: _withCtx(function () { return [
                                _createVNode(_component_el_dropdown_item, null, {
                                    default: _withCtx(function () { return [
                                        _createVNode(_component_router_link, { to: "/user/info" }, {
                                            default: _withCtx(function () { return _cache[0] || (_cache[0] = [
                                                _createElementVNode("span", null, "个人信息", -1)
                                            ]); }),
                                            _: 1
                                        })
                                    ]; }),
                                    _: 1
                                }),
                                _createVNode(_component_el_dropdown_item, { onClick: logout }, {
                                    default: _withCtx(function () { return _cache[1] || (_cache[1] = [
                                        _createElementVNode("span", null, "安全退出", -1)
                                    ]); }),
                                    _: 1
                                })
                            ]; }),
                            _: 1
                        })
                    ]; }),
                    default: _withCtx(function () {
                        var _a, _b;
                        return [
                            _createElementVNode("div", _hoisted_1, [
                                _createElementVNode("p", _hoisted_2, _toDisplayString(((_a = userInfo.value) === null || _a === void 0 ? void 0 : _a.displayName) || ((_b = userInfo.value) === null || _b === void 0 ? void 0 : _b.userName) || '未知用户'), 1)
                            ])
                        ];
                    }),
                    _: 1
                })
            ]));
        };
    }
});

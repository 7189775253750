import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue";
var _hoisted_1 = { class: "ac_sidebar-menu" };
import { computed, onMounted, ref, watch } from 'vue';
import { getMenuList } from '@/layout/components/sidebar/menu/menuFactory';
import MenuItem from '@/layout/components/sidebar/menu/MenuItem.vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup: function (__props) {
        var store = useStore();
        var route = useRoute();
        onMounted(function () {
            activeMenu(route);
        });
        var defaultActive = ref('');
        watch(function () { return route.name; }, function (nv) {
            activeMenu(route);
        });
        /**
         * 选中当前菜单
         */
        var activeMenu = function (route) {
            var _a;
            defaultActive.value = (route === null || route === void 0 ? void 0 : route.meta.activeMenu) || ((_a = route.matched[1]) === null || _a === void 0 ? void 0 : _a.name);
        };
        var collapse = computed(function () { return store.getters['layout/collapse']; });
        var menuList = computed(getMenuList);
        return function (_ctx, _cache) {
            var _component_el_menu = _resolveComponent("el-menu");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_el_menu, {
                    collapse: collapse.value,
                    "default-active": defaultActive.value
                }, {
                    default: _withCtx(function () { return [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList.value, function (menu) {
                            return (_openBlock(), _createBlock(MenuItem, {
                                key: menu.name,
                                menu: menu
                            }, null, 8, ["menu"]));
                        }), 128))
                    ]; }),
                    _: 1
                }, 8, ["collapse", "default-active"])
            ]));
        };
    }
});

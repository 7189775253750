import { defineComponent as _defineComponent } from 'vue';
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
import { computed } from 'vue';
import { useRoute } from 'vue-router';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Breadcrumb',
    setup: function (__props) {
        var routes = computed(function () {
            return useRoute().matched.filter(function (item) {
                if (item.meta.title) {
                    return item;
                }
            });
        });
        return function (_ctx, _cache) {
            var _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item");
            var _component_el_breadcrumb = _resolveComponent("el-breadcrumb");
            return (_openBlock(), _createBlock(_component_el_breadcrumb, {
                class: "ac_breadcrumb",
                separator: "/"
            }, {
                default: _withCtx(function () { return [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(routes.value, function (item) {
                        return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
                            key: item.path
                        }, {
                            default: _withCtx(function () { return [
                                _createElementVNode("span", null, _toDisplayString(item.meta.title), 1)
                            ]; }),
                            _: 2
                        }, 1024));
                    }), 128))
                ]; }),
                _: 1
            }));
        };
    }
});

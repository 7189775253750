import { defineComponent as _defineComponent } from 'vue';
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { computed } from 'vue';
import { useStore } from 'vuex';
export default /*@__PURE__*/ _defineComponent({
    __name: 'Hamburger',
    setup: function (__props) {
        var store = useStore();
        var isCollapse = computed(function () { return store.getters['layout/collapse']; });
        var collapseToggle = function () { return store.commit('layout/collapseToggle'); };
        return function (_ctx, _cache) {
            return (_openBlock(), _createElementBlock("i", {
                class: _normalizeClass(["c-icon", isCollapse.value ? 'ci-menu-expand' : 'ci-menu-collapse']),
                onClick: collapseToggle
            }, null, 2));
        };
    }
});

import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

import { onMounted, ref } from 'vue'
import SideBer from './components/sidebar/index.vue'
import Tools from './components/tools/index.vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'


export default {
  __name: 'index',
  setup(__props) {

const route = useRoute()

const showParent = ref(true)
onMounted(() => {
  updateShowParent(route)
  window.addEventListener('message', function(e) {
    if(e.data.small === 1) {
      showParent.value = false
    } else if(e.data.small === 0) {
      showParent.value = true
    }
  })
})

onBeforeRouteUpdate((e) => {
  updateShowParent(e)
})
const updateShowParent = (route) => {
  if(route.query.small === '1') {
    showParent.value = false
  } else if(route.query.small === '0') {
    showParent.value = true
  }
}

return (_ctx, _cache) => {
  const _component_el_aside = _resolveComponent("el-aside")
  const _component_el_header = _resolveComponent("el-header")
  const _component_router_view = _resolveComponent("router-view")
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")
  const _component_el_main = _resolveComponent("el-main")
  const _component_el_container = _resolveComponent("el-container")

  return (_openBlock(), _createBlock(_component_el_container, { style: {"min-width":"1366px"} }, {
    default: _withCtx(() => [
      (showParent.value)
        ? (_openBlock(), _createBlock(_component_el_aside, {
            key: 0,
            class: "ac_aside",
            width: ""
          }, {
            default: _withCtx(() => [
              _createVNode(SideBer)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_el_container, { style: {"max-height":"100vh"} }, {
        default: _withCtx(() => [
          (showParent.value)
            ? (_openBlock(), _createBlock(_component_el_header, {
                key: 0,
                class: "ac_header",
                height: "60px"
              }, {
                default: _withCtx(() => [
                  _createVNode(Tools)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_main, { class: "flex-1 p-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_el_scrollbar, null, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-3", { 'small-view' : !showParent.value }])
                  }, [
                    _createVNode(_component_router_view)
                  ], 2)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

}
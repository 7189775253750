import { defineComponent as _defineComponent } from 'vue';
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = { class: "ac_toolbar" };
var _hoisted_2 = { class: "left-menu" };
var _hoisted_3 = { class: "right-menu" };
import Hamburger from './Hamburger.vue';
import Breadcrumb from './Breadcrumb.vue';
import Search from './HeaderSearch.vue';
import ScreenFull from './ScreenFull.vue';
import Remind from '@/layout/components/tools/Remind.vue';
import UserInfo from '@/layout/components/tools/UserInfo.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'index',
    setup: function (__props) {
        return function (_ctx, _cache) {
            var _component_el_divider = _resolveComponent("el-divider");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createVNode(Hamburger, { class: "ac_toolbar-item" }),
                    _createVNode(_component_el_divider, { direction: "vertical" }),
                    _createVNode(Breadcrumb)
                ]),
                _createElementVNode("div", _hoisted_3, [
                    _createVNode(Search, { class: "ac_toolbar-item" }),
                    _createVNode(Remind, { class: "ac_toolbar-item" }),
                    _createVNode(ScreenFull, { class: "ac_toolbar-item" }),
                    _createVNode(UserInfo, { class: "ac_toolbar-item" })
                ])
            ]));
        };
    }
});

// const BASE_API = '8.140.155.218'
// const BASE_API = '49.75.185.61'
// const BASE_API = 'wl.nongwu.weile-water.com'
// const BASE_API = '192.168.0.153'
export default {
  TITLE: '农村污水',
  BASE_API: 'https://wl.nongwu.weile-water.com/api',
  // BASE_API: 'http://192.168.5.121:15987/api',
  WEBSOCKET_API: 'wss://wl.nongwu.weile-water.com',
  // WEBSOCKET_API: 'ws://192.168.0.153:15987',
  IMAGE_PREFIX: 'https://wl.nongwu.weile-water.com/'
}
